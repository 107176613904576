.documents ion-grid > ion-row > ion-col {
    display: flex;
    align-items: center
}

.documents > ion-row.document-item {
    border-top: 1px solid #00000030 !important;
    padding: 20px;
}

.documents > ion-row.document-item:last-of-type {
    border-bottom: 1px solid #00000030 !important;
}

.documents .document-item p.document-name, 
.documents .document-item p.document-info {
    margin: 0;
    padding: 0;
}

.documents ion-grid > ion-row > ion-col:nth-child(2) {
    text-align: left;
}