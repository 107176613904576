.signup {
    height: 100vh; 
    display: flex; 
    align-items: center;
}

.bottom-div {
    width: 100%;
    text-align: center;
    margin-top: 2em;
}

h1 {
    text-align: center; 
    margin-bottom: 2em;
}

ion-button {
    margin-top: 7em;
}