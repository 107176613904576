div.add-new-user {
  display: flex; 
  align-items: center; 
  margin: 2em 0;
  width: 100%;
  height: auto;
}

h1 {
  text-align: center; 
  margin-bottom: 2em;
}

ion-avatar {
  height: 100px; 
  width: 100px; 
  margin: 0 auto;
}

.cancel-div {
  width: 100%;
  text-align: center;
  margin-top: 2em;
}

form {
  width: 100%;
}

ion-chip {
  margin: 1em auto;
}