.items-list {
    width: 100%; 
    text-align: center;
}

ion-list {
    width: 90%; 
    margin: 0 auto;
}

ion-col {
    text-align: center;
}

.items-list ion-col {
    text-align: left;
}

.table-container {
    margin-top: 8px;
    flex-grow: 1;
    overflow-x: auto;
    margin: 0px -32px;
    margin-top: 8px;
    padding: 0 32px 32px 32px;
}