.forgot-password {
    height: 100vh; 
    display: flex; 
    align-items: center;
}

h1 #heading {
    text-align: center; 
    margin-bottom: 3em;
}

.login-link-div {
    width: 100%;
    text-align: center;
    margin-top: 1em;
}