.stand-col {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

ion-button {
    margin: 0;
    padding: 0;
}

/* .heading {
    font-size: 1.2em;
    font-weight: bold;
} */
