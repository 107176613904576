ion-header {
    border-radius: 50% !important;
}

.header-md::after {
    display: none;
}

ion-toolbar {
    position: relative;
    --background: transparent;
    background-image: linear-gradient(90deg, #7fba50, #7fba50);
}

.background {
    /* background-color: var(--ion-color-primary) !important; */
    height: 220vh;
    text-align: center;
}

.page-content {
    /* border-radius: 40px;  */
    z-index: 9999; 
    overflow: hidden; 
    background: var(--ion-color-primary-contrast);
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    margin: auto;
    text-align: left;
    padding-bottom: 5em;
}

.time-div {
    position: absolute;
    left: 1em;
    top: 1em;
    color: white;
}

@media screen and (max-width: 990px) {
    .time-div {
        position: static;
        margin: auto;
    }
}