
@font-face {
    font-family: 'Roboto';
    src: url('/assets/Roboto/Roboto-Light.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-display: swap;
}

* {
    font-family: 'Roboto', sans-serif;
}

table {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

ion-label {
    font-size: .75rem !important;
}

ion-item {
    font-size: .85rem !important;
}

ion-select-option {
    font-size: .85rem !important;
}

.table-container {
    margin-top: 8px;
    flex-grow: 1;
    overflow-x: auto;
    margin: 0px -32px;
    margin-top: 8px;
    padding: 0 32px 32px 32px;
}

ion-modal .modal-wrapper:not(ion-modal.not-fullscreen .modal-wrapper)  {
    height: 100% !important;
    width: 100% !important;
    padding-bottom: 1em;
    overflow-y: scroll;
    overflow-x: auto;
}

.flex-align-center {
    display: flex;
    align-items: center;
}

.datetime-popover .DayPicker-Day {
    cursor: pointer !important;
    width: 40px;
}

/* .datetime-popover-alt .popover-content{ --width: 500px; --height: 600px; } */


.datetime-popover .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
.datetime-popover .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: var(--ion-color-primary);
}

.DayPicker-Day--today {
    color: var(--ion-color-primary);
}


.DayPicker-Day:focus-visible {
    outline-color: var(--ion-color-primary);
}

.datetime-popover-picker .popover-content{ --width: 270px; }

@media screen and (min-width: 675px) {
    .datetime-popover .DayPicker {
        display: inline-block;
        font-size: 1.1rem;
    }
    
    .datetime-popover .DayPicker-Day {
        height: 50px;
        width: 50px;
    }
    
    .datetime-popover .DayPicker-Week {
        border-collapse: separate;
        border-spacing: 5px 5px;
    }
    
    .datetime-popover .DayPicker-Month {
        border-spacing: 20px !important;
        border-collapse: initial;
    }
    
}

@media screen and (max-width: 600px) {
    ion-modal .modal-wrapper {
        height: 100% !important;
        width: 100% !important;
        padding-bottom: 1em;
        overflow-y: scroll;
        overflow-x: auto;
    }    

}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
    }
