  .output{
    height: 60px;
    font-size: 0.8rem;
  }

  .error{
    color: var(--error);
  }
  
  /* progress bar styles */
  .progress-bar {
    height: 5px;
    background: var(--ion-color-primary);
    margin-top: 20px;
  }
