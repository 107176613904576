.entry-logs {
    height: auto;
}

.pagination {
    display: flex;
    list-style: none;
    cursor: pointer;
  }
  
  .pagination a {
    padding: 5px;
    color: var(--ion-color-primary);
  }
  
  .pagination__link {
    font-weight: bold;
  }
  
  .pagination__link--active a {
      text-decoration: underline;
    /* color: #fff;
    background: var(--ion-color-primary); */
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
  }
  
  ion-row {
      box-shadow: none !important;
  }