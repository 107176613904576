.cls-1{
    fill:#231f20;
}

.red {
    fill: red !important;
}

.green {
    fill: var(--ion-color-success);
}

.yellow {
    fill: var(--ion-color-warning);
}

.pointer {
    cursor: pointer !important;
}