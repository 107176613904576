.edit-user {
    display: flex; 
    align-items: center;
    width: 100%;
}

h1 {
    text-align: center; 
    margin-bottom: 2em;
}

ion-avatar {
    height: 100px; 
    width: 100px; 
    margin: 0 auto;
}

.cancel-div {
    width: 100%;
    text-align: center;
    margin-top: 2em;
}

form {
    width: 100%;
}

ion-chip {
    margin: 1em auto;
}

#units-select {
    visibility: hidden !important;
}

/* .select-popover .popover-content {
    width: 325px;
}

.select-popover .popover-content ion-list {
    width: 100%;
    margin-right: 0;
}

ion-select {
    width: 270px;
} */

